<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Reset Password" :hideLogin="true" />

    <div class="mainBox mainBox30 border shadow-sm">
        <div class="row">
            <div class="col-12 pb-3">
                <input type="password" v-model="password1" placeholder="Password" class="form-control" />
            </div>

            <div class="col-12 pb-3">
                <input type="password" v-model="password2" placeholder="Reconfirm password" class="form-control"/>
            </div>

            <div class="col-12 text-end">
                <button class="btn btn-secondary" @click="resetPassword">Submit</button>
            </div>

        </div>
    </div>    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import auth from '@/functions/auth'

export default {
    name: 'ResetPassword',
    components: { TopNavigation, Alert },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()

        const password1 = ref(null)
        const password2 = ref(null)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const resetPassword = () => {

            if (password1.value !== password2.value) {

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {
                // Proceed tor set password (axios)
                const p = {
                    password: password1.value
                }
                
                let url = '/auth/resetPassword/' + route.params.activationCode

                axios.put( url, p )
                    .then((res) => {

                        console.info('resetPassword res', JSON.stringify(res))
                        if (res.data.status === 1) {

                            setTimeout(() => {
                                router.push('/auth/login')
                                .then(() => {
                                    // refresh the page so that can capture sessionId in store
                                    router.go() 
                                })
                            , 1000})

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Password is reset."
                            })
                        } 
                    })
                    .catch((error) => {
                        console.log('setPassword | FAILURE', JSON.stringify(error))

                        if (error.status === 'invalid_action' || error.status === 'hacking_attempt' || error.status === 'refId_not_found') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Your reset password link is not working. Please report the error (error code) to our support if you continue to face the same problem. (' + error.code + ')'
                            })

                        } else if (error.status === 'refId_expired') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Your reset password link is expired. (' + error.code + ')'
                            })

                        } else {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                        }
                    })

            }
        }

        onMounted(() => {
            console.info('onMounted')
            auth.clearStorage()

            if (route.params.activationCode === null || route.params.activationCode === undefined) {
                // Redirect to page not found if no activationCode was detected
                router.push('/pageNotFound')
            }
        })

        return { 
            alert, closeAlert, password1, password2, resetPassword 
        }
    }
}
</script>

<style>

</style>